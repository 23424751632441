import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from "@material-ui/core"
import NavBar from "./NavBar";
import TimeLineEntry from "./Resume/TimeLine/TimeLineEntry/TimeLineEntry";
import TimeLineLabel from "./Resume/TimeLine/TimeLineLabel/TimeLineLabel";

//images
import Image1 from "../images/html-css-js-lg.jpg"

const useStyles = makeStyles( theme =>({
    mainContainer: {
        background: "#233"
    },
    timeLine: {
        position: "relative",
        padding: "1rem",
        margin: "0 auto",
        "&:before": {
            content: "''",
            position: "absolute",
            height: "100%",
            border: "1px solid tan",
            right: "40px",
            top: 0,
        },
        "&:after": {
            content: "''",
            display: "table",
            clear: "both",
        },
        [theme.breakpoints.up("md")]:{
            padding: "2rem",
            "&:before":{
                left: "calc(50% - 1px)",
                right: "auto",
            },
        },

    },
    timeLineItem:{
        padding: "1rem",
        borderBottom: "2px solid tan",
        position: "relative",
        margin: "1rem 3rem 1rem 1rem",
        clear: "both",
        "&:after": {
            content: "''",
            position: "absolute",
        },
        "&:before":{
            content: "''",
            position: "absolute",
            right: "-0.625rem",
            top: "calc(50% - 5px)",
            borderStyle: "solid",
            borderColor: "tomato tomato transparent transparent",
            borderWidth: "0.625rem",
            transform: "rotate(45deg)",
        },
        [theme.breakpoints.up("md")]:{
            width: "44%",
            margin: "1rem",
            "&:nth-of-type(2n)":{
                float: "right",
                margin: "1rem",
                borderColor: "tan",
            },
            "&:nth-of-type(2n):before": {
                right: "auto",
                left: "-0.625rem",
                borderColor: "transparent transparent tomato tomato",
            },
        },
    },
    timeLineYear: {
        textAlign: "center",
        maxWidth: "9.375rem",
        margin: "0 3rem 0 auto",
        fontSize: "1.8rem",
        background: "tomato",
        color: "white",
        lineHeight: 1,
        padding: "0.5rem 0 auto",
        "&:before": {
            display: "none",
        },
        [theme.breakpoints.up("md")]:{
            textAlign: "center",
            margin: "0 auto",
            "&:nth-of-type(2n)": {
                float: "none",
                margin: "0 auto",
            },
            "&:nth-of-type(2n):before":{
                display: "none",
            }
        }
    },
    heading: {
        color: "tomato",
        padding: "3rem 0",
        textTransform: "uppercase",
    },
}));

const Resume = () => {
    const classes = useStyles();
    return  (
        <>
            <NavBar />
            <Box className={classes.mainContainer} component="header">
                <Typography
                    variant="h4"
                    align="center"
                    className={classes.heading}
                    >
                    work experience
                </Typography>
                <Box component="div" className={classes.timeLine}>
                    
                    <TimeLineLabel labelHead="Year"/>
                    <TimeLineEntry
                        entryImage={Image1}
                        entryHead="Project Name"
                        entrySubHead="Company"
                        entryBlurb="A colorful description here..."
                    />
                    <TimeLineEntry
                        entryImage={Image1}
                        entryHead="Project Name"
                        entrySubHead="Company"
                        entryBlurb="A colorful description here..."
                    />                                       
                    
                    <TimeLineLabel labelHead="Year"/>                    
                    <TimeLineEntry
                        entryImage={Image1}
                        entryHead="Project Name"
                        entrySubHead="Company"
                        entryBlurb="A colorful description here..."
                    />
                    <TimeLineEntry
                        entryImage={Image1}
                        entryHead="Project Name"
                        entrySubHead="Company"
                        entryBlurb="A colorful description here..."
                    />                                       
                    
                    <TimeLineLabel labelHead="Year"/>
                    <TimeLineEntry
                        entryImage={Image1}
                        entryHead="Project Name"
                        entrySubHead="Company"
                        entryBlurb="A colorful description here..."
                    />
                </Box>
            </Box>
        </>
    );
};

export default Resume;