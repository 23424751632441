import React from 'react';
import {Route} from 'react-router-dom';
import './App.css';
// Material Component that Performs a Global CSS Reset 
import CssBaseline from '@material-ui/core/CssBaseline';
import Home from './components/Home';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import FramerOne from "./components/FramerPlayground/FramerOne"

function App() {
  return (
    <>
    <CssBaseline />
    <Route exact path="/" component={Home} />
    <Route path="/resume" component={Resume} />
    <Route path="/portfolio" component={Portfolio} />
    <Route path="/contact" component={Contact} />
    <Route path="/framer-one" component={FramerOne} />
    </>
  );
}

export default App;
