import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    BottomNavigation,
    BottomNavigationAction,
} from "@material-ui/core";
import {
    Facebook,
    Twitter,
    Instagram,
} from "@material-ui/icons";


const useStyles = makeStyles({
    root: {
        "& .MuiBottomNavigationAction-root": {
            minWidth: 0,
            maxWidth: 250,
        },
        "& .MuiSvgIcon-root": {
            fill: "tan",
            "&:hover": {
                fill: "tomato",
                fontSize: "1.8rem",
            },
        },
    },
});

const Footer = () => {

    const classes = useStyles();

    return (
        <>
            <BottomNavigation widht="auto" style={{ background: "#222"}}>
                <BottomNavigationAction
                    className={classes.root}
                    style={{pading: 0}}
                    icon={<Facebook />}
                />    
                <BottomNavigationAction
                    className={classes.root}
                    style={{pading: 0}}
                    icon={<Instagram />}
                />    
                <BottomNavigationAction
                    className={classes.root}
                    style={{pading: 0}}
                    icon={<Twitter />}
                />    

            </BottomNavigation>
        </>
    )
}


export default Footer;