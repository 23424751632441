import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid
} from "@material-ui/core";
import project1 from "../images/html-css-js-lg.jpg";
import project2 from "../images/javascript-fullstack.jpg";
import project3 from "../images/mern-stack.jpg";
import project4 from "../images/react-redux.jpg";
import NavBar from "./NavBar";
import ProjectCard from "./ProjectCard/ProjectCard"

const useStyles = makeStyles({
  mainContainer: {
    background: "#233",
    height: "100%",
  },
});

const Portfolio = () => {
  const classes = useStyles();

  return (
    <>
      <Box component="div" className={classes.mainContainer}>
        <NavBar />
        <Grid container justify="center" >
          <ProjectCard 
            cardImage={project1}
            cardTitle="Project 1"
            cardText="Lizards are a widespread group of squamate reptiles, with
            over 6,000 species, ranging across all continents except
            Antarctica"
          />
          <ProjectCard 
            cardImage={project2}
            cardTitle="Project 2"
            cardText="Lizards are a widespread group of squamate reptiles, with
            over 6,000 species, ranging across all continents except
            Antarctica"
          />
          <ProjectCard 
            cardImage={project3}
            cardTitle="Project 3"
            cardText="Lizards are a widespread group of squamate reptiles, with
            over 6,000 species, ranging across all continents except
            Antarctica"
          />
          <ProjectCard 
            cardImage={project4}
            cardTitle="Project 4"
            cardText="Lizards are a widespread group of squamate reptiles, with
            over 6,000 species, ranging across all continents except
            Antarctica"
          />
        </Grid>
      </Box>
    </>
  );
};

export default Portfolio;
