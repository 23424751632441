import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
    CardMedia,
} from "@material-ui/core";


const useStyles = makeStyles(theme=>({
    timeLineItem:{
        padding: "1rem",
        borderBottom: "2px solid tan",
        position: "relative",
        margin: "1rem 3rem 1rem 1rem",
        clear: "both",
        "&:after": {
            content: "''",
            position: "absolute",
        },
        "&:before":{
            content: "''",
            position: "absolute",
            right: "-0.625rem",
            top: "calc(50% - 5px)",
            borderStyle: "solid",
            borderColor: "tomato tomato transparent transparent",
            borderWidth: "0.625rem",
            transform: "rotate(45deg)",
        },
        [theme.breakpoints.up("md")]:{
            width: "44%",
            margin: "1rem",
            "&:nth-of-type(2n)":{
                float: "right",
                margin: "1rem",
                borderColor: "tan",
            },
            "&:nth-of-type(2n):before": {
                right: "auto",
                left: "-0.625rem",
                borderColor: "transparent transparent tomato tomato",
            },
        },
    },
    subheading: {
        color: "white",
        padding: 0,
        textTransform: "uppercase",
    },
    media: {
        height: 90,
        width: 160,
        margin: "0px auto",
    },
}));

function TimeLineEntry(props) {
    const classes = useStyles();
  return (
    <>
      <Box component="div" className={classes.timeLineItem}>
       <CardMedia className={classes.media} image={props.entryImage} />
        <Typography variant="h5" align="center" className={classes.subheading}>
          {props.entryHead}
        </Typography>
        <Typography variant="body1" align="center" style={{ color: "tomato" }}>
          {props.entrySubHead}
        </Typography>
        <Typography variant="subtitle1" align="center" style={{ color: "tan" }}>
          {props.entryBlurb}
        </Typography>
      </Box>
    </>
  );
}

export default TimeLineEntry;
