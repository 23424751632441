import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import avatar from '../avatar.png';
import Typed from 'react-typed';
import {
    Typography,
    Avatar,
    Grid,
    Box
} from '@material-ui/core';


// CSS Styles for this component
const useStyles = makeStyles(theme =>({
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        margin: theme.spacing(1)
    },
    title: {
        color: "tomato"
    },
    subtitle: {
        color: "tan",
        marginBottom: "3rem",
    },
    typedContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100vw",
        textAlign: "center",
        zIndex: "1"
    }
}));


export const Header = () => {
    const classes = useStyles();
    return (
        <Box className={classes.typedContainer}>
            <Grid container justify="center" >
                <Avatar className={classes.avatar} src={avatar} alt="Mike's Avatar" />
            </Grid>
            <Typography className={classes.title} variant="h4">
                <Typed 
                    strings={["Mike Harrington"]} 
                    typeSpeed={40}
                /> 
            </Typography>
            <Typography className={classes.subtitle} variant="h5">
            <Typed 
                    strings={["Game Art","Web Design", "Web Development", "AR & VR","and Prototyping" ]} 
                    typeSpeed={40}
                    backSpeed={60}
                    loop
                />
            </Typography>
        </Box>
    )
}
