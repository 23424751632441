import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  cardContainer: {
    maxWidth: 345,
    margin: "5rem auto",
  },
});

function ProjectCard(props) {
  const classes = useStyles();
  return (
    <>
      {/* Project 1 */}
      <Grid item xs={12} sm={8} md={6}>
        <Card className={classes.cardContainer}>
          <CardActionArea>
            <CardMedia
              component="img"
              alt="Project 1"
              height="140"
              image={props.cardImage}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {props.cardTitle}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.cardText}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary">
              More
            </Button>
            <Button size="small" color="primary">
              Live Demo
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
}

export default ProjectCard;
