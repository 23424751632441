import React from "react";
import {motion} from "framer-motion";

const FramerOne = () => {
    return (
        <>
            <motion.div animate={{scale: 0.5}}/>
            <div style={{color: "white"}}>
                Hello world
            </div>
        </>
    )
}

export default FramerOne
